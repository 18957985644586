<template>
    <div class="_form-without-shadows">
        <h2 class="_title text-center">{{title}}</h2>
        <b-form @submit="onSubmit" v-if="show">
            <b-form-group
                id="input-group-1"
            >
                <b-form-input
                id="input-1"
                v-model="form.email"
                type="email"
                placeholder="Email"
                required
                ></b-form-input>
            </b-form-group>
            <b-button v-if="sent == false" type="submit">Отправить</b-button>
        </b-form> 
        <div class="text-center _response" v-if="sent">
            <p class="h4">
                Спасибо за заявку!<br> Мы свяжемся с вами в ближайшее время
            </p> 
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'OrderForm',
    props: ['title'],
        data() {
        return {
            sent: false,
            form: {
                email: '',
                //checked: []
            },
            show: true
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault()
            axios.post("./forms/mail.php",JSON.stringify(this.form)).then(res => {this.sent = true; this.show = false;})
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    ._success {
        font-size: 2rem;
        line-height: 1;
        margin: 2rem 0;
        padding: 1rem;
   }
</style>
