<template>
    <div class="_first-screen">
        <b-container>
            <div class="_main-title-container">
                <h1>Франшиза<br> Клиники<br> «9 месяцев»</h1>
                <p class="_description">Стабильный бизнес с оборотом<br class="d-sm-none"> 40 000 000<br class="d-none"> в год для города миллионника.</p>
            </div>

            <div class="_request-btns">
                <PresentationBtn class="_grey mr-sm-5"/>
                <button class="_btn-transparent" v-b-modal.business-plan><span><i class="_diagram-ico"></i></span> Получить бизнес-план</button>
            </div>  
            <b-row>
                <b-col cols="4" md="3">
                    <div class="_advantages-item d-md-flex align-items-center">
                        <span><i class="_investments-ico mr-md-3"></i></span>
                        <div>
                            <h3>Инвестиции</h3>
                            <span>14 млн +</span>
                        </div>
                    </div>    
                </b-col>    
                <b-col cols="4" md="3">
                     <div class="_advantages-item d-md-flex align-items-center">
                        <span><i class="_payback-ico mr-md-3"></i></span>
                        <div>
                            <h3>Окупаемость</h3>
                            <span>3 года</span>
                        </div>
                    </div>  
                </b-col> 
                <b-col cols="4" md="3">
                     <div class="_advantages-item d-md-flex align-items-center">
                        <span><i class="_profit-ico mr-md-3"></i></span>
                        <div>
                            <h3>Прибыль</h3>
                            <span>570 тыс. руб./мес.</span>
                        </div>
                    </div>  
                </b-col> 
            </b-row>    
        </b-container>
    </div>    
</template>

<script>
import PresentationBtn from '../components/PresentationBtn.vue'

export default {
  name: 'FirstScreen',
  components: {
     PresentationBtn
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    ._first-screen {
        position: relative;
        @media (min-width: 992px) {
            &::after{
                content:'';
                position: absolute;
                top: 0;
                right: 0;
                background: url('../assets/img/top-banner.jpg') top right / contain no-repeat;
                width: 100%;
                height: 51rem;
                @media (max-width:1200px) {
                    height: 44vw;
                }             
                z-index: -2;
            }
        }    
        @media (min-width: 576px) {
            padding: 26.1875rem 0 8rem;
            &:before {
                content:'';
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                bottom: -11rem;
                background: url('../assets/img/ariadna-top.svg') 0 0/contain no-repeat;
                z-index: -1;
            }
        }
        //background: url('../assets/img/main.png') top right / 40vw no-repeat;
 
        ._main-title-container {
            max-width: 40rem;
            h1 {
                font-style: normal;
                font-weight: 700;
                font-size: 4.375rem;
                line-height: 4.375rem;
                //color: #303030;
                margin-bottom: 1.25rem;
                @media (max-width: 576px) {
                    font-size: 2.625rem;
                    line-height: 2.75rem;
                }
            }
            ._description {
                font-weight: 600;
                font-size: 1.75rem;
                line-height: 2.625rem;
                @media (max-width: 576px) {
                    max-width: 18.75rem;
                    font-size: 1.125rem;
                    line-height: 1.5625rem;
                }
            }
            @media (max-width: 576px) {
                position: relative;
                margin-top: 5rem;
                padding-top: 12.5rem;
                margin-left: -15px;
                margin-right: -15px;
                padding-left: 1.25rem;
                &:before {
                    content:'';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background: url('../assets/img/top-banner-m.jpg') right top /59vw no-repeat;
                    z-index: -1;
                }
                &:after {
                    content:'';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 3.75rem;
                    background: url('../assets/img/ariadna-top-mobile.svg') 0 0/contain no-repeat;
                    z-index: -1;
                }
            }   
        }
        ._request-btns {
            padding: 7rem 0 4.375rem;
            display: flex;
            align-items: center;
            @media (max-width:576px) {
                padding: 2rem 0;
            }
        }
        ._advantages-item {
            @media (max-width: 576px) { 
                text-align: center; 
                i {
                    width: 2.625rem;
                    height: 2.625rem;
                }
            }
            h3 {
                font-family: Arial, sans-serif;
                font-weight: 500;
                font-size: 1.875rem;
                line-height: 1.625rem;
                margin-bottom: .5rem;
                @media (max-width: 576px) { 
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1rem;
                    line-height: 1.625rem;
                    color: #000000;
                    margin-bottom: 0;
                }
            }
            span {
                display: block;
                font-weight: 300;
                font-size: 1.5rem;
                line-height: 1.625rem;
                @media (max-width: 576px) { 
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1rem;
                    line-height: .875rem;
                }
            }
        }
        @media (max-width:1100px) and (min-width: 700px) { 
            background-size: 35vw;
        }
        @media (max-width: 576px) {
            margin-bottom: 3rem;
            ._btn,._btn-transparent {
                background: transparent;
                box-shadow: none;
                font-style: normal;
                font-weight: normal;
                font-size: 1rem;
                line-height: 1.1875rem;
                text-align: center;
                text-decoration-line: underline;
                color: #0B0B0B;
                max-width: 50%;
                i {
                    margin-right: 0;
                    margin-left: 0;
                }
            }
            ._diagram-ico {
                width: 1.8125rem;
                height: 1.5625rem;
            }
        }
    }
    
</style>
