<template>
  <div>
    <b-modal id="business-plan" class="_custom-modal" hide-footer centered title="Заявка на бизнес-план">
        <OrderForm title="Получить Бизнес-план"/>
    </b-modal>
  </div>
</template>

<script>
import OrderForm from '../components/OrderForm.vue'
export default {
  name: 'Modal',
  components: {
    OrderForm
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  #business-plan {
    ._title {
      font-size: 3rem;
      margin: 0 0 1.5rem;
      padding: 0;
      @media (max-width:576px) {
        font-size: 1.5rem;
      }
    }
    .modal-content {
     //background: linear-gradient(to right, #85c13f 0%,#45a842 47%,#028f45 100%);
     background: transparent;
     text-align: center;
     border-radius: 1.25rem;
     border: none;
     padding: 0;
     .modal-body {
      padding: 0;
     }
     .modal-title {
        display: none;
     }
     .modal-header {
      border-bottom: none;
      padding: 0;
     }
      .close {
          padding: 0;   
          padding-right: 10px;
          padding-bottom: 10px;
          font-weight: 300;
          font-size: 3.5rem;
          color: #ffffff;
          opacity: 1;
      }
    } 
  }
</style>
