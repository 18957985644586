<template>
    <a href="./9_month_f.pdf" class="_btn" download><i class="_pdf-ico"></i> <span>Скачать презентацию</span></a> 
</template>

<script>
export default {
  name: 'PresentationBtn',
  data() {return{
    pdf: '../assets/doc/9_month_f.pdf',
  }},
  methods: {
    DownloadPdf() {
        alert('123');
    }
  }
}
</script>


<style lang="scss" scoped>
    @media (max-width:600px) {
        ._grey i{
            background: url('../assets/img/pdf-logo-gray.svg') center center / contain no-repeat;
            width: 2.1875rem;
            height: 1.5625rem;
        } 
    }
</style>
