<template>
  <header class="position-absolute w-100" :class="{ active: isActive }">
    <b-container class="px-sm-0">
      <b-navbar toggleable="md" type="light" class="_custom-navbar">
        <b-navbar-brand href="#"><img src="../assets/img/logo.svg" class="_logo" alt="logo"></b-navbar-brand>
        <div class="d-flex align-items-center">
        <a class="d-flex d-md-none mr-3" href="tel:+7 843 207 04 40"><i class="_phone-ico"></i></a>
        <a class="d-flex d-md-none mr-5" href="javascript:void(0);" v-b-modal.business-plan><i class="_request-ico-rainbow"></i></a>
        <b-navbar-toggle class="d-flex d-md-none p-0" target="nav-collapse" v-on:click="headerActive()">
          <template>
            <i class="_menu-ico" target="nav-collapse"></i>
          </template>
        </b-navbar-toggle>
      </div>

        <div class="pt-sm-5 d-sm-flex align-items-center w-100">
          <b-collapse class="justify-content-center" id="nav-collapse" is-nav>
            <b-navbar-nav class="pt-3 pt-sm-0">
              <b-nav-item href="#" v-on:click="anchor('about')">О нас</b-nav-item>
              <b-nav-item href="#" v-on:click="anchor('formats')">Форматы бизнеса</b-nav-item>
              <b-nav-item class="text-white" href="#" v-on:click="anchor('gallery')">Фотогалерея</b-nav-item>
              <b-nav-item class="text-white" href="#" v-on:click="anchor('contacts')">Контакты</b-nav-item>
            </b-navbar-nav>
          </b-collapse>

          <div class="_request-block d-none d-md-block">
            <div><button v-b-modal.business-plan><i class="_request-ico mr-2"></i>Оставить заявку</button></div>
            <a href="tel:+7 843 207 04 40">+7 843 207 04 40</a>
          </div>
        </div>
      </b-navbar>
    </b-container>
  </header> 
</template>

<script>
export default {
  name: 'Navbar',
  data() {    
    return {
      isActive: false
    }
  },
  methods: {
    headerActive() {
      if (this.isActive == false) {
          this.isActive = true
          console.log(this.isActive)
      }
      else {
        this.isActive = false
        console.log(this.isActive)
      }     
    },
    anchor: function(target) {
      this.headerActive()
      let Top = document.getElementById(target).offsetTop;
      console.log(Top);
      window.scrollTo({
          top: Top,
          left: 0,
          behavior: 'smooth'
      });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  header {
    margin-top: 2.5rem;
    left: 0;
    z-index: 1;
    transition: all .3s;
    &.active {
      background: #ffffff;
      box-shadow: 0 1.5rem 1rem rgba(0,0,0,.45);
    }
    @media (max-width: 600px) { 
      margin-top: 1.25rem;
    }
  }
  ._custom-navbar {
    align-items: start;
    @media (min-width:1200px) {
      width: 105%
    }
    .navbar-toggler {
      border: none;
    }
    ._logo {
      max-width: 20.375rem;
      position: relative;
      top: -1rem;
      @media (max-width:992px) {
        max-width: 10rem;
        top: -.5rem;
      }  
      @media (max-width:576px) {
        max-width: 8.625rem;
      }   
    }
    @media (max-width:992px) {
      ._request-ico {
        background: url('../assets/img/request-ico-grey.svg') center center/contain no-repeat;
      }
    }
    .nav-item .nav-link {
      font-size: 1.4375rem;
      line-height: 1.9375rem;
      @media (max-width:576px) {
          color: #000;
          font-size: 1.2rem;
          line-height: 1.1;
      }
      &:hover {
        color: #7CC507;
      }
    }
    @media (min-width: 992px) {
      padding: 0;
      .navbar-nav {
        width: 100%;
        justify-content: space-between;
        padding-right: 1.25rem;
      }
    }
    @media (min-width: 992px) {
      .nav-item.text-white a{
       color:#ffffff !important;
      } 
    }

    ._request-block {
        position: relative;
        font-style: normal;
      div {
        position: absolute;
        width: 100%;
        left: 0;
        top:-2.1875rem;
      }  
      button {
        font-size: 1.125rem;
        display: flex;
        align-items: center;
        background: none;
        border: none;
        color:#ffffff;
        @media (max-width:992px) {
          color: #000;
        }  
        margin: 0 auto .625rem;
        &:hover {
          opacity: .8;
        }
      }  
      a {
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.5rem;
        color: #4F4F4F;
        background: #FFFFFF;
        @media (max-width:992px) {
          background: #ffdc00;
        } 
        border-radius: 1.5625rem;
        width: 15.625rem;
        height: 3.125rem;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .3s;
        &:hover {
          text-decoration: none;
          background: #7CC507;
          color:#ffffff;
        }
      }   
    }
  }
</style>
