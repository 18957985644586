<template>
    <div class="_about" id="about">
        <section class="container text-center">
            <h2>11 лет успешной работы</h2>
        </section>

        <section class="container">
            <div class="_description my-4 my-md-5">
                <p>Основа нашего успеха – высокий уровень квалификации врачей, современное оборудование экспертного класса и особенная атмосфера уюта и комфорта для пациентов. Бренд клиники зарегистрирован в Российском патентном бюро. Приоритетные специализации:  ведение беременности, гинекология, педиатрия, вакцинация, УЗИ.</p>
            </div>    
   
            <b-row class="text-center _services">
                <b-col sm="4" class="mb-3">
                    <img src="../assets/img/uzi.jpg" class="img-fluid" alt="Узи">
                    <h3 class="my-3">УЗИ</h3>
                    <p>УЗИ – оборудование премиум- класса и высокая точность диагностики</p>
                </b-col>   
                <b-col sm="4" class="mb-3">
                    <img src="../assets/img/ginecology.jpg" class="img-fluid" alt="Узи">
                    <h3 class="my-3">Гинекология</h3>
                    <p>Гинекология – врачи-эксперты высокого уровня </p>
                </b-col> 
                <b-col sm="4" class="mb-3">
                    <img src="../assets/img/pediatrics.jpg" class="img-fluid" alt="Педиатрия">
                    <h3 class="my-3">Педиатрия</h3>
                    <p>Педиатрия – атмосфера комфорта и уюта </p>
                </b-col>                       
            </b-row>  
        </section> 

        <section class="container my-5">
            <div class="_video-wrap">
                <div class="_video-responsive">
                    <iframe src="https://www.youtube.com/embed/S_ad3F9-2b4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>    
            </div>
        </section>

        <section class="container my-5 text-center">
            <h2 class="my-4 my-sm-5 pt-sm-5">Наш опыт и поддержка<br class="d-none d-sm-block"> в открытии бизнеса</h2>
            <VueSlickCarousel v-bind="settings" class="_advantages">
                <div class="px-3">
                    <img src="../assets/img/advantages-1.svg" class="mx-auto" alt="">
                    <h3 class="my-4">Будем проводником в открытии Вашего бизнеса</h3>
                    <p class="text-left">Открытие даже небольшой клиники требует участия разных специалистов и
                    координации многочисленных задач. 
                    Мы даем решения на все вопросы, чтобы Франчайзи не тратил время и
                    деньги, а получал прибыль с первых дней. </p>
                </div>
                <div class="px-3">
                    <img src="../assets/img/advantages-2.svg" class="mx-auto" alt="">
                    <h3 class="my-4">Медицина – этичный бизнес и бизнес на века!</h3>
                    <p class="text-left">Умение лечить людей — это уважаемый высоко-оцениваемый труд во все
                    времена, а медицинские услуги показывают устойчивость к экономическим и
                    геополитическим кризисам. Даже сложный 2020-й мы закончили с ростом
                    выручки на 5%.</p>
                </div>
                <div class="px-3"> 
                    <img src="../assets/img/advantages-3.svg" class="mx-auto" alt="">
                    <h3 class="my-4">Применяем IТ технологии!</h3>
                    <p class="text-left">Мы пользуемся уникальной медицинской информационной системой – это
                    упрощает работу сотрудникам и экономит Ваши деньги. А мобильное
                    приложение и личный кабинет по достоинству оценили наши пациенты.</p>
                </div>                
            </VueSlickCarousel>    
        </section>    
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  name: 'About',
  components: { VueSlickCarousel},
  data() {
      return {
        settings: {
            dots:false,
            arrows:false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                breakpoint: 576,
                settings: {
                    arrows:true,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
                }
            ]
        },
      }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    ._about {
        position: relative;
        h3 {
            font-weight: 500;
            font-size: 1.5625rem;
            @media (max-width:600px) {
                font-size: 1.125rem;
                line-height: 1.75rem;
            }    
        }
        p {
            font-weight: 300;
            font-size: 1.3125rem;
            line-height: 1.625rem;
            @media (max-width:600px) {
                font-size: 1.125rem;
                line-height: 1.375rem;
            }    
        }
        ._title {
            font-size: 3.125rem;
            @media (max-width:600px) {
                font-size: 1.875rem;
               line-height: 3rem;    
            }
            &+p {
              font-weight: 300;
              font-size: 2.125rem;
              @media (max-width:600px) {
                font-family: 'Rubik', sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 1.125rem;
                line-height: 1.75rem;
                text-align: center;
              }
            }
        }
        ._h-logo {
            max-width: 10.625rem;
            margin: -1.2rem 0 0 1rem;
            @media (max-width:600px) {
              max-width: 7.875rem;
              margin: -1.2rem 0 0 1rem;
            }    
        }
        ._description {
            max-width: 65.8125rem;
            margin: auto;
        }
        ._services p {
            max-width: 21.125rem;
            margin: auto;
            text-align: left;  
        }
        ._video-wrap {
            max-width: 50.4375rem;
            margin: auto;
            // @media (min-width:1300px) {
            //     &:before {
            //         content:'';
            //         position: absolute;
            //         top: 25%;
            //         left: 0;
            //         background: url('../assets/img/yellow-decor.png') center right / contain no-repeat;
            //         width: 13.0625rem;
            //         height:62rem;
            //         z-index: -1;
            //     }
            // }   
            @media (max-width:576px) {
                margin-left: -.9375rem;
                margin-right: -.9375rem;
            }    
        }
        ._advantages {
            img {
                width: 100%;
                height: 4.0625rem;
            }
            h3 {
                font-weight: 500;
                font-size: 1.4375rem;
                height: 3.125rem;
                overflow: hidden;
                @media (max-width:600px) {
                    height: auto;
                    overflow: auto;
                    font-size: 1.1875rem;
                    line-height: 1.4375rem;
                } 
            }
            p {
                font-weight: 300;
                font-size: 1.25rem;
                line-height: 1.625rem;
                text-align: left;
                @media (max-width:600px) {
                    font-size: 1.125rem;
                    line-height: 1.375rem;
                } 
            }
        }
        @media (min-width:1300px) {
            &:before {
                content:'';
                position: absolute;
                top: -2%;
                left: 5%;
                background: linear-gradient(to right, #85c13f 0%,#45a842 47%,#028f45 100%);
                width: 5.625rem;
                height:5.625rem;
                border-radius: 50%;
                z-index: -1;
            }
        }
    }

</style>
