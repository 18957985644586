<template>
    <footer id="contacts">
      <i class="_logo my-4 mx-auto"></i>
      <div class="_footer-bg py-5">
        <div class="container _footer-content">
        <a href="tel:+7 (843) 207-04-40" class="_phone">+7 (843) 207-04-40</a>
          <div class="row mt-5">
            <div class="col-sm-8"> 
              <p class="_offer">Все представленные на сайте экономические показатели носят прогнозный характер и не являются публичной офертой.</p>
			</div>
            
            <div class="col-sm-4">
              <p>Наши адреса:<br> 
              Казань, пр. Победы, 152/33<br>
              ул. Маяковского, 30</p>

              <div class="row my-3 my-sm-5">
                <div class="col-sm-auto">
                    <a href="https://c9m.ru/" target="_blank">www.c9m.ru</a>
                </div>
                <div class="col-sm">
                    <a href="mailto:fr@c9m.ru">Email: fr@c9m.ru</a>
                </div>
              </div>
            </div>
          </div>

          <div class="row align-items-center">
            <div class="col-sm-8">
              <div class="_social-btns mb-3 mb-sm-0">
                <a href="https://wa.clck.bar/78432070440" class="_mini mr-2" target="_blank"><img src="../assets/img/whatsapp.svg"></a>
                <!-- <a href="" class="_mini" target="_blank"><img src="../assets/img/intagram.svg"></a>
                <a href="" class="_mini" target="_blank"><img src="../assets/img/facebook.svg"></a> -->
                <a href="https://vk.com/clinic9m" class="_mini" target="_blank"><img src="../assets/img/vk.svg"></a>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="d-flex">
                <div class="mr-2"><a href="http://onelink.to/qertdt"><img src="../assets/img/google-play.svg" class="img-fluid" width="150" height="45" alt="Google Play"></a></div>
                <div><a href="http://onelink.to/qertdt"><img src="../assets/img/app-store.svg" class="img-fluid" width="150" height="45" alt="App Store"></a></div> 
              </div>  
            </div>  
          </div>

          <div class="row align-items-center my-2">
            <div class="col-sm-8 order-1 order-sm-0">
              Клиника “9 Месяцев” © Copyrights <span class="_year"></span>
            </div>
            <div class="col-sm-4 order-0 order-sm-1 my-3 my-sm-0">
              <a href="https://c9m.ru/informatsiya/politika-konfidencialnosti/" target="_blank">Политика конфидециальности</a>
            </div>
          </div>
          

          <p class="text-uppercase text-center h6 mt-5 my-sm-5"><b>О возможных противопоказаниях необходимо проконсультироваться с врачом</b></p>
		  <div class="text-sm-right">
		    <a href="https://xn----7sbbnfb4all5cn2i.xn--p1ai/"><img src="../assets/img/actix-black.svg" width="60" alt="делаем-сайты.рф"></a>
		  </div>
        </div>
      </div>
    </footer> 
</template>

<script>

import OrderForm from '../components/OrderForm.vue'
export default {
  name: 'Footer',
  components: {
    OrderForm
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
._logo {
    background: url('../assets/img/logo.svg') center center / contain no-repeat;
    width: 11.625em;
    height: 7.375em;
    display: block;
    @media(max-width:1200px){
        width: 6.5rem;
        height: 4.125rem;
    }
}
._social-btns {  
    a {
        display: inline-block;
        transition: transform .3s;
        &:hover {
            transform: scale(1.3);
        }
    }
    ._medium img {
        height: 2rem;
    }
    ._mini img{
        height: 1.5rem;
    }
}
 footer {
    margin-top:8.75rem;
    @media(max-width:576px) {
        margin-top:4rem;
    }
    a {
        color: inherit;
        &:hover {
            color: inherit; 
        }
    }
    ._phone {
        font-size: 1.75rem;
        text-decoration: none;
    }
    ._footer-content{
        max-width: 55.3125rem;
        ._offer {
            max-width: 26.5625rem;
        }
    }
    ._footer-bg {
        @media(min-width: 576px){
            background: url('../assets/img/ariadna.svg') left center/ contain no-repeat,#f6f6f6;
        }
    background: url('../assets/img/ariadna-footer.svg')  left bottom/ contain no-repeat,#f6f6f6;
    }
} 
</style>
