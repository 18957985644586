<template>
    <div>
        <section class="_circles-decoration">
		<div class="container">
			<h2 class="my-5 py-sm-5 text-center">Присоединяйтесь к нашей команде!</h2>

			<div class="_form-without-shadows">
				<div class="_title-block">
					<h2 class="_title">Свяжитесь сейчас!</h2>
					<span class="_subtitle">Получите презентации</span>
					<span class="_addition">Мы готовы рассказать, как работает наш бизнес.</span>
				</div>	
				<form class="needs-validation" id="vacanciesForm" @submit="onSubmit">
					<div class="form-group mb-3">
						<input class="form-control" type="text"  name="name" placeholder="Ваше ФИО" v-model="form.name" required>
					</div>
					<div class="form-group mb-3">
						<input type="text"  class="form-control" name="phone" placeholder="Телефон" v-model="form.phone" required>
					</div>
                    <div class="form-group mb-3">
						<input type="text"  class="form-control" name="city" placeholder="Город" v-model="form.city" required>
					</div>
					<!-- <div class="form-group mb-3">
						<input type="text"  class="form-control" name="function" placeholder="Желаемая Должность/Специальность">
					</div>
					<div class="form-group mb-3">
						<textarea class="form-control" name="comment" placeholder="Комментарий" rows="2"></textarea>
					</div>
					<div class="form-group mb-3">
						<label for="myfile" class="mb-2">Прикрепить резюме (по желанию)</label>
						<input class="form-control" type="file" name="myfile[]" multiple id="myfile" placeholder="Прикрепить резюме (по желанию)">
					</div> -->

                    <p class="text-center"><small>Нажимая кнопку «Отправить»,<br> Я выражаю свое согласие с обработкой моих персональных данных</small></p>
                    <!-- 
					<div class="d-flex justify-content-center mt-3">
						<div class="form-check">
							<input class="form-check-input" type="checkbox" id="callBack_text4" checked="">
							<label class="form-check-label" for="callBack_text4">
							Я согласен на обработку персональных данных
							</label>
						</div>
					</div>
					<div class="text-center my-3">
						<div class="_btn-link d-inline-block" data-bs-toggle="modal" data-bs-target="#polzovatelskoe-soglashenie">Пользовательское соглашение</div>
					</div> -->
                    <button v-if="!sent" type="submit" class="mx-auto">Отправить</button>
					<div class="text-center _response" v-if="sent">
						<!-- <input type="hidden" name="agreement"> -->
                        <p class="h4">
                            Спасибо за заявку!<br> Мы свяжемся с вами в ближайшее время
                        </p> 
					</div>
				</form>
			</div>

		</div>
	</section>	
        <!-- <b-form @submit="onSubmit" v-if="show">
            <b-form-group
                id="input-group-1"
            >
                <b-form-input
                id="input-1"
                v-model="form.email"
                type="email"
                placeholder="Email"
                required
                ></b-form-input>
            </b-form-group>
            <b-button type="submit">Отправить</b-button>
        </b-form> 
        <p class="_success" v-if="sent">
            Спасибо за заявку!<br> Мы свяжемся с вами в ближайшее время
        </p>  -->
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'Form',
        data() {
        return {
            sent: false,
            form: {
                name:'',
                phone: '',
                city: ''
                //checked: []
            },
            show: true
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault()
            console.log(JSON.stringify(this.form))
            axios.post("./forms/mail.php",JSON.stringify(this.form)).then(res => {this.sent = true; this.show = false;})
        }
    }
}
</script>


