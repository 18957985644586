<template>
  <div class="_gallery-slider container my-5 py-5" id="gallery">

    <h2 class="text-center mb-5">Жизнь в клинике “9 месяцев”</h2>
    <!-- <VueSlickCarousel v-bind="settings">
        <div v-for="(item,index) in imgs" :key="item.src" >
            <img :src="require('../assets/img/photo/m/'+(index+1)+'.jpg')" alt="" @click="showMultiple(index)">
        </div> 
    </VueSlickCarousel> -->
    <!-- all props & events -->
    <vue-easy-lightbox
        scrollDisabled
        escDisabled
        moveDisabled
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
    ></vue-easy-lightbox>
  
    <div class="row no-gutters">
      <div class="col-6 col-sm-3">
        <img class="m" :src="require('../assets/img/photo/1.jpg')" alt="" @click="showMultiple(0)">
        <img class="m" :src="require('../assets/img/photo/2.jpg')" alt="" @click="showMultiple(1)">
      </div>  
      <div class="col-6 col-sm-3">
        <img class="l" :src="require('../assets/img/photo/3.jpg')" alt="" @click="showMultiple(2)">
      </div>
      <div class="col-6 col-sm-3">
        <img class="m" :src="require('../assets/img/photo/4.jpg')" alt="" @click="showMultiple(3)">
        <img class="m" :src="require('../assets/img/photo/5.jpg')" alt="" @click="showMultiple(4)">
      </div>
      <div class="col-6 col-sm-3">
        <img class="l" :src="require('../assets/img/photo/6.jpg')" alt="" @click="showMultiple(5)">
      </div>
      <div class="col-6 col-sm-3">
        <img class="l" :src="require('../assets/img/photo/7.jpg')" alt="" @click="showMultiple(6)">
      </div>  
      <div class="col-6 col-sm-3">
        <img class="l" :src="require('../assets/img/photo/8.jpg')" alt="" @click="showMultiple(7)">
      </div>
      <div class="col-6 col-sm-3">
        <img class="l" :src="require('../assets/img/photo/9.jpg')" alt="" @click="showMultiple(8)">
      </div>
      <div class="col-6 col-sm-3">
        <img class="l" :src="require('../assets/img/photo/10.jpg')" alt="" @click="showMultiple(9)">
      </div>
    </div>

  </div>
</template>
 
<script>

  import VueSlickCarousel from 'vue-slick-carousel'
  
  import VueEasyLightbox from 'vue-easy-lightbox'
  

  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
  
  let Imgs = [
         {src: require('../assets/img/photo/1.jpg')},
         {src: require('../assets/img/photo/2.jpg')},
         {src: require('../assets/img/photo/3.jpg')},
         {src: require('../assets/img/photo/4.jpg')},
         {src: require('../assets/img/photo/5.jpg')},
         {src: require('../assets/img/photo/6.jpg')},
         {src: require('../assets/img/photo/7.jpg')},
         {src: require('../assets/img/photo/8.jpg')},
         {src: require('../assets/img/photo/9.jpg')},
         {src: require('../assets/img/photo/10.jpg')},
        ]
   export default {
    name: 'Gallery',
    components: { VueSlickCarousel,VueEasyLightbox },
    data() {
      return {
        visible: false,
        index: 0, // default: 0  
        imgs: Imgs,
        settings: {
            dots: true,
            arrows:false,
            infinite: true,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1,
            initialSlide: 0,
            autoplay: true,
            responsive: [
                {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
                },
                {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
                },
                {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
                }
            ]
        },
      }
    },
    methods: {
    //   showSingle() {
    //     this.imgs = 'http://via.placeholder.com/350x150'
    //     // or
    //     this.imgs = {
    //       title: 'this is a placeholder',
    //       src: 'http://via.placeholder.com/350x150'
    //     }
    //     this.show()
    //   },
      showMultiple(index) {
        this.imgs = Imgs
        // or
        // this.imgs = [
        //   { title: 'test img', src: 'http://via.placeholder.com/350x150' },
        //   'http://via.placeholder.com/350x150'
        // ]
        // allow mixing

        this.index = index // index of imgList
        this.show()
      },
      show() {
        this.visible = true
      },
      handleHide() {
        this.visible = false
      }
    }
  }
</script>

<style lang="scss">
    ._gallery-slider {
        position: relative;
        margin-left: -.3125rem;;
        margin-right: -.3125rem;;
        img {
          //padding: 0 .3125rem;
          padding: .625rem;
          width: 100%;
          object-fit: cover;
          cursor: zoom-in;
          border-radius: 1.5625rem;
        }
        .m {
          height: 30vh;
          max-height: 268px;
          @media (max-width:576px) {
            max-height: 134px;
          }
        }
        .l {
          height: 60vh;
          max-height: 537px;
          @media (max-width:576px) {
            max-height: 268px;
          }
        }

        @media (min-width:1300px) {
            &:before {
                content:'';
                position: absolute;
                z-index: -1;
                top: 12%;
                left: -18%;
                background: linear-gradient(to right, #f8e921 0%,#f8cd2d 47%,#f7ae3a 100%);
                width: 9.125rem;
                height:9.125rem;
                border-radius: 50%;
                
            }
            &:after {
                content:'';
                position: absolute;
                top: 42%;
                left: -14%;
                background: linear-gradient(to right, #85c13f 0%,#45a842 47%,#028f45 100%);
                width: 5.625rem;
                height:5.625rem;
                border-radius: 50%;
                z-index: -1;
            }
        }
    }
    @media (min-width:1200px) {
      ._circles-decoration{
        position: relative;
        padding-bottom: 8.75rem;
        &:before {
            content: "";
            position: absolute;
            top: calc(100% - 3.125rem);
            left: calc(100% - 10rem);
            width: 9.125rem;
            height: 9.125rem;
            border-radius: 50%;
            z-index: 0;
            background: linear-gradient(90deg, #f8e921 0, #f8cd2d 47%, #f7ae3a);
        }
        &:after {
          content: "";
          position: absolute;
          top: 92%;
          left: calc(100% - 6.625rem/1.5);
          width: 6.625rem;
          height: 6.625rem;
          border-radius: 50%;
          z-index: 0;
          background: linear-gradient(90deg, #03C100 31.25%, #21E221 100%);
       }
      } 
    }
    .vel-img {
      background-color: unset !important;
      box-shadow: 0px 0px 64px -7px rgba(34, 60, 80, 0.2) !important;
      padding: 0 !important;
      border-radius: 0 !important;
    }
</style>