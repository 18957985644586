<template>
  <div class="container-fluid _slider-wrap" id="formats">
      <section class="container text-center mb-3 mb-sm-5">
       <h2 class="mt-sm-5 pt-3 pt-sm-5">Форматы бизнеса</h2>
       <p class="_subtitle">Идеально подходим для открытия клиник до 250 кв.м. Выберите удобный формат Вашей будущей клиники</p>
      </section>
      <div class="_slider">

        <carousel-3d :width="width" :height="height" :display="display" :controls-visible="true" :controls-prev-html="'<button class=slick-prev></button>'" :controls-next-html="'<button class=slick-next></button>'" 
               :controls-width="30" :controls-height="60" :clickable="true"  :perspective="0" :border="0" inverseScaling:="300"> 
          <slide :index="0">
              <div class="_slide" ref="slideHeight">
                <b-row class="align-items-center _title-block">
                  <b-col sm="5">
                    <div class="_slide-ico mx-auto">
                      <img src="../assets/img/biz-1.svg" class="img-fluid" alt="">                                        
                    </div>
                    <p class="_mini-title">Женское и детское внимание</p> 
                  </b-col>
                  <b-col sm="7" class="_hidden"><h2>Женское и детское внимание</h2></b-col>
                </b-row> 

                <b-row class="align-items-center">
                    <b-col sm="5">
                        <ul class="list-unstyled _parameters">
                          <li>
                            <span class="_title">Площадь</span> 
                            <p>150 <span class="_unit">м<sup>2</sup></span></p>
                            <p>6 <span class="_unit">кабинета</span></p>
                          </li>
                          <li>
                            <span class="_title">Инвестиции</span> 
                            <p>14,3 <span class="_unit">млн руб.</span></p>
                          </li>
                          <li>
                            <span class="_title">Ежемесячный доход</span> 
                            <p>570 <span class="_unit">тыс. руб. </span></p>
                          </li>
                        </ul>  
                    </b-col>
                    <b-col sm="7" class="_hidden">
                        <img src="../assets/img/6-rooms.jpg" class="img-fluid _plan mb-3" alt="" @click="showMultiple(1)">

                        <ul class="list-unstyled _custom-numbering ml-3">
                          <li><i>1</i>Процедурный кабинет</li>
                          <li><i>2</i>Кабинет специалистов</li>
                          <li><i>3</i>УЗИ</li>
                          <li><i>4</i>Кабинет гинеколога</li>
                          <li><i>5</i>Педиатр</li>
                          <li><i>6</i>Дневной стационар</li>
                        </ul>  
                    </b-col>  
                </b-row> 

                <button href="#" class="_btn mx-auto mt-3 mt-sm-5" v-b-modal.business-plan><i class="_diagram-ico-w"></i> <span>Получить бизнес-план</span></button> 

              </div> 
          </slide>
          <slide :index="1">
              <div class="_slide">
                <b-row class="align-items-center _title-block">
                  <b-col sm="5">
                    <div class="_slide-ico mx-auto">
                      <img src="../assets/img/biz-2.svg" class="img-fluid" alt="">                                        
                    </div>
                    <p class="_mini-title">Ведение беременности</p> 
                  </b-col>
                  <b-col sm="7" class="_hidden"><h2>Ведение беременности</h2></b-col>
                </b-row> 

                <b-row class="align-items-center">
                    <b-col sm="5">
                        <ul class="list-unstyled _parameters">
                          <li>
                            <span class="_title">Площадь</span> 
                            <p>115 <span class="_unit">м<sup>2</sup></span></p>
                            <p>4 <span class="_unit">кабинета</span></p>
                          </li>
                          <li>
                            <span class="_title">Инвестиции</span> 
                            <p>13 <span class="_unit">млн руб.</span></p>
                          </li>
                          <li>
                            <span class="_title">Ежемесячный доход</span> 
                            <p>400 <span class="_unit">тыс. руб. </span></p>
                          </li>
                        </ul>  
                    </b-col>
                    <b-col sm="7" class="_hidden">
                        <img src="../assets/img/4-rooms.jpg" class="img-fluid _plan mb-3" alt="" @click="showMultiple(2)">

                        <ul class="list-unstyled _custom-numbering ml-3">
                          <li><i>1</i>Процедурный кабинет</li>
                          <li><i>2</i>Кабинет специалистов</li>
                          <li><i>3</i>УЗИ</li>
                          <li><i>4</i>Кабинет гинеколога</li>
                        </ul>  
                    </b-col>  
                </b-row> 

                <button href="#" class="_btn mx-auto mt-3 mt-sm-5" v-b-modal.business-plan><i class="_diagram-ico-w"></i> <span>Получить бизнес-план</span></button> 
                
              </div>  
          </slide>
          <slide :index="2">
             <div class="_slide">
                <b-row class="align-items-center _title-block">
                  <b-col sm="5">
                    <div class="_slide-ico mx-auto">
                      <img src="../assets/img/biz-3.svg" class="img-fluid" alt="">                                        
                    </div>
                    <p class="_mini-title">Женское и детское здоровье со своей лабораторией</p> 
                  </b-col>
                  <b-col sm="7" class="_hidden"><h2>Женское и детское здоровье со своей лабораторией</h2></b-col>
                </b-row> 

                <b-row class="align-items-center">
                    <b-col sm="5">
                        <ul class="list-unstyled _parameters">
                          <li>
                            <span class="_title">Площадь</span> 
                            <p>180 <span class="_unit">м<sup>2</sup></span></p>
                            <p>8 <span class="_unit">кабинета</span></p>
                          </li>
                          <li>
                            <span class="_title">Инвестиции</span> 
                            <p>15,3 <span class="_unit">млн руб.</span></p>
                          </li>
                          <li>
                            <span class="_title">Ежемесячный доход</span> 
                            <p>680 <span class="_unit">тыс. руб. </span></p>
                          </li>
                        </ul>  
                    </b-col>
                    <b-col sm="7" class="_hidden">
                        <img src="../assets/img/8-rooms.jpg" class="img-fluid _plan mb-3" alt="" @click="showMultiple(0)">

                        <ul class="list-unstyled _custom-numbering ml-3">
                          <li><i>1</i>Процедурный кабинет</li>
                          <li><i>2</i>Кабинет специалистов</li>
                          <li><i>3</i>УЗИ</li>
                          <li><i>4</i>Дневной стационар</li>
                          <li><i>5</i>Педиатр</li>
                          <li><i>6</i>Кабинет гинеколога</li>
                          <li><i>7</i>Кабинет гинеколога</li>
                          <li><i>8</i>УЗИ</li>
                        </ul>  
                    </b-col>  
                </b-row> 

                <button href="#" class="_btn mx-auto mt-3 mt-sm-5" v-b-modal.business-plan><i class="_diagram-ico-w"></i> <span>Получить бизнес-план</span></button> 

              </div>  
          </slide>
        </carousel-3d>

        <vue-easy-lightbox
          scrollDisabled
          escDisabled
          moveDisabled
          :visible="visible"
          :imgs="imgs"
          :index="index"
          @hide="handleHide"
       ></vue-easy-lightbox>
      </div>
  </div>
</template>
 
<script>
import { async } from 'q';
import { Carousel3d, Slide } from 'vue-carousel-3d';
import VueEasyLightbox from 'vue-easy-lightbox'


  let Imgs = [
         {src: require('../assets/img/8-rooms-b.jpg')},
         {src: require('../assets/img/6-rooms-b.jpg')},
         {src: require('../assets/img/4-rooms-b.jpg')},
        ]

export default {
  name: 'Slider',
  components: {
    Carousel3d,
    Slide,
    VueEasyLightbox
  },
  data() {
    return {
      height:'',
      width:'',
      display:'3',
      visible: false,
      index: 0, // default: 0  
      imgs: Imgs,
    }
  },
  created() {
    window.addEventListener('resize', this.adaptiveHeight)
  },
  destroyed() {
    window.addEventListener('resize', this.adaptiveHeight)
  },
  mounted() {
      setTimeout(() => this.adaptiveHeight(), 100);
      //this.load()
      //this.adaptiveHeight()
  },
  methods: {
      showMultiple(index) {
        this.imgs = Imgs
        this.index = index // index of imgList
        this.show()
      },
      show() {
        this.visible = true
      },
      handleHide() {
        this.visible = false
      },
      adaptiveHeight(e) {

          let w = window.innerWidth
          //let h = this.$refs.slideHeight.clientHeight
          if(w>1920||w<=1920&&w>1600){
            this.width = 1920/2
            this.height = 1920/2
          }
          else if (w<=1600&&w>1500) {
            this.width = 760 
            this.height = 760
          }
          else if (w<=1500&&w>600) {
            this.width = 600 
            this.height = 700
          }
          else if (w<600){
            this.width = w - 30
            this.height = this.$refs.slideHeight.clientHeight+100
          }


        
          //this.height = h

          

        // if (w>1500){
        //   this.width = 760;
        //   this.height = h*.9;
        // }
        // if (w<1500){
        //   this.width = w/2.3;
        //   this.height = h*.85;
        // }
        // if (w<1400){
        //   this.width = w/2.3;
        //   this.height = h*.8;
        // }
        // if (w<1200){
        //   this.width = w/2;
        //   this.height = h*.8;
        // }
        // if (w<1100){
        //   this.width = w/2;
        //   this.height = h*.75;
        // }
        // if (w<860){
        //   this.width = w/1.5;
        //   this.height = h*.85;
        // }
        // if (w<700){
        //   this.width = w/1.5;
        //   this.height = h*.9;
        // }
        // if (w<576){
        //   this.width = w-20;
        //   this.height = h+55;
        // }
      }
  },
}
</script>

<style lang="scss" scoped>
 ._subtitle {
  max-width: 33.375rem;
  margin-left: auto;
  margin-right: auto;
 }
  .carousel-3d-slide {
    background-color: transparent;
    img {
     max-width: 100%;
     width: auto;
    }
  }
  ._slider {
    max-width: 100rem;
    margin: auto;
    @media(max-width:1100px){
      margin-left: -15px;
      margin-right: -15px;
    }
    ._plan {
      cursor: zoom-in;
    }
    ._slide {
      width: auto;
      height: auto;
      background: #FFFFFF;
      box-shadow: 0px 0px 15px rgba(109, 109, 109, 0.33);
      border: 4px solid transparent;
      border-radius: 1.25rem;
      transition: all .3s;
    }
    .current ._slide {
        border: 4px solid #F9B60F;        
    }
    ._slide {
      padding: 3.125rem; 
      margin: 1.25rem;
      @media (max-width: 600px) {
        padding: 2.25rem;
      }
    }
    ._slide-ico {
        width: 7.25rem;
        height: 7rem;
        background: linear-gradient(334.24deg, #43C701 -9.84%, #C9D401 121.28%);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%; 
        padding: 1.5rem;
        @media (max-width: 576px) {
          width: 6.125rem;
          height: 5.9375rem;
        } 
    }
    h2 {
        font-style: normal;
        font-weight: normal;
        font-size: 2.3125rem;
        line-height: 2.25rem;
        color: #000000;
        @media (max-width: 600px) {
          font-size: 1.5625rem;
          line-height: 1.875rem;
          text-align: center;
          padding: 1.4375rem 0;
        }
    }  

    ._parameters {
      margin-left: 2.5rem;
       @media (max-width: 600px) {
           margin-left: 0.5rem;
       }
      ._title {
        color: #B0A3A3;
        font-size: 1.125rem;
        line-height: 1.25rem;
        @media (max-width: 600px) {
          font-size: 1rem;
          line-height: 1.25rem;
        }
      }
      p {
        font-weight: bold;
        font-size: 2.375rem;
        line-height: 3.5rem;
        margin-bottom: 0;
        @media (max-width: 600px) {
          font-size: 2.125rem;
          line-height: 2.75rem;  
        }  
        ._unit {
          font-weight: normal;
          font-size: 1.5625rem;
           @media (max-width: 600px) {
             font-size: 1.125rem;
           } 
        }
      }
    }
    ._custom-numbering {
      font-family: 'Rubik', sans-serif;
      font-size: 1.125rem;
      line-height: 1.5rem;
      i {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        font-style: normal;
        width: 1.25rem;
        height: 1.25rem;
        color:#FFFFFF;
        background: #000000;
        border-radius: 50%;
        margin-right: 1.875rem;
      }
    }
    ._hidden {
      display: none;
      @media (max-width: 576px) {
        display: none !important;
      }    
    }
    ._btn {
      display: none;
    }
    ._mini-title {
      font-family: 'Rubik', sans-serif;
      font-size: 1.5625rem;
      line-height: 2.0625rem;
      text-align: center;
      margin: 1.5rem 0;
      display: block;
      @media (max-width:576px) {
        font-size: 1rem;
        line-height: 1;
        height: 3.125rem;
      }
    }
    .current {    
      ._slide {
        ._hidden {
          display: block;
        }
        ._btn {
          display: flex;
        }
        ._mini-title {
          @media (min-width: 576px) {
            display: none;
          }
        }  
      }  
      @media (min-width: 576px) {
        ._slide-ico {
          background: linear-gradient(334.24deg, #FF7601 -9.84%, #FFB901 57.61%, #D9D601 121.28%);
        }
      }
    }
    [class*="right"] {
      .row {
        justify-content: flex-end;
      }
    }
  }
</style>