<template>
    <div class="_arguments">
        <section class="container">
            <h2 class="text-center mb-sm-5 my-md-5 py-md-5">Что Вы получаете<br class="d-none d-sm-block"> как франчайзи</h2>
            
            <div v-if="mobile == false">
                <b-row v-for="(items, key) in results" :key="key">
                    <b-col md="6" lg="4" v-for="item in items" :key="item.title" >
                        <div class="_card" v-bind:class="{ _extracheck: item.isActive == true }">
                            <h3>{{ item.title }}</h3>
                            <p>{{ item.description }}</p>
                        </div>    
                    </b-col> 
                </b-row>
            </div>

       
            <b-tabs pills class="_custom-tabs" v-if="mobile == true">
                <b-tab title="1" active>
                    
                    <b-row v-for="(items, key) in results" :key="key">
                        <b-col md="6" lg="4" v-for="item in items.slice(0, 3)" :key="item.title" >
                            <div class="_card" v-bind:class="{ _extracheck: item.isActive == true }">
                                <h3>{{ item.title }}</h3>
                                <p>{{ item.description }}</p>
                            </div>    
                        </b-col> 
                    </b-row>
        
                </b-tab>
                <b-tab title="2">

                    <b-row v-for="(items, key) in results" :key="key">
                        <b-col md="6" lg="4" v-for="item in items.slice(3, 6)" :key="item.title" >
                            <div class="_card" v-bind:class="{ _extracheck: item.isActive == true }">
                                <h3>{{ item.title }}</h3>
                                <p>{{ item.description }}</p>
                            </div>    
                        </b-col> 
                    </b-row>

                </b-tab>
                <b-tab title="3">
                    <b-row v-for="(items, key) in results" :key="key">
                        <b-col md="6" lg="4" v-for="item in items.slice(6, 9)" :key="item.title" >
                            <div class="_card" v-bind:class="{ _extracheck: item.isActive == true }">
                                <h3>{{ item.title }}</h3>
                                <p>{{ item.description }}</p>
                            </div>    
                        </b-col> 
                    </b-row>
                </b-tab>
            </b-tabs>
       
            <PresentationBtn class="mx-auto my-5"/>        
        </section>
    </div>
</template>

<script>
import PresentationBtn from '../components/PresentationBtn.vue'

export default {
  name: 'Arguments',
  components: {
     PresentationBtn
  },
  data() {    
    return {
        mobile: false,
        results: {
            "0": [
                {
                    "title": "Дизайн-проект помещения",
                    "description": "Помещение будущей клиники должно быть эргономичным и при этом подходить для лицензирования, поэтому мы детально прорабатываем расстановку. Мы знаем, на какие аспекты стоит обратить внимание при выборе помещения. Мы дадим планировку и цветовую гамму.",
                    "isActive": false
                },
                {
                    "title": "Пакет документов для лицензирования",
                    "description": "Учтем все нюансы и передадим пакет документов, необходимых для получения лицензии.",
                    "isActive": false
                },
                {
                    "title": "Поставки по выгодным ценам",
                    "description": "Обеспечим выгодные условия работы с лабораториями и поставщиками оборудования – сэкономите - до 30%.",
                    "isActive": false
                },
                {
                    "title": "Сайт с 1 000 000+ посещений в год",
                    "description": "Наш сайт в топовых позициях поисковиков. Вы будете размещены в нем, как подразделение клиники, и получите клиентов с первых дней работы.",
                    "isActive": true
                },
                {
                    "title": "Помощь с продвижением",
                    "description": "Передадим шаблоны для ведения соцсетей на 90 дней, запустим таргетированную рекламу, откроем доступ к фотобанку маркетинговых материалов, и макетов рекламы. Сможете получать заявки с первых дней работы.",
                    "isActive": false
                },
                {
                    "title": "Передовые технологии",
                    "description": "Удобная облачная система управления клиникой экономит средства за счет эффективно выстроенных алгоритмов . Мобильное приложение для пациентов (с онлайн-записью на прием и личной медицинской картой) удерживает лояльность пациентов.",
                    "isActive": true
                },   
                {
                    "title": "Контроль качества медицинской помощи",
                    "description": "Безопасность – главное в медицине. С удаленным контролем качества Вы получите уверенность, что ваши врачи назначают лечение корректно, а документы оформлены правильно.",
                    "isActive": false
                }, 
                {
                    "title": "Отлаженный финансовый учет",
                    "description": "Настроим систему учета, чтобы в любой момент Вы знали основные показатели Вашего бизнеса и могли принимать правильные решения на основании точных данных.",
                    "isActive": false
                },
                {
                    "title": "Эффективная система управления сотрудниками",
                    "description": "У нас есть подробные инструкции для работы каждого специалиста и KPI – для оценки их работы. Система построена так, чтобы сотрудники были заинтересованы в результатах!",
                    "isActive": false
                }
            ],
        }    
    }
  },
  mounted() {
    let docWidth = document.body.clientWidth;
    
    if (docWidth <= 600) {
        this.mobile = true; 
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
   ._arguments {
        position: relative;
        @media (min-width:1300px) {
            &:before {
                content:'';
                position: absolute;
                top: 50%;
                left: 3%;
                background: linear-gradient(to right, #85c13f 0%,#45a842 47%,#028f45 100%);
                width: 5.625rem;
                height:5.625rem;
                border-radius: 50%;
                z-index: -1;
            }
            &:after {
                content:'';
                position: absolute;
                top: 20rem;
                right: -3%;
                background: linear-gradient(to right, #f8e921 0%,#f8cd2d 47%,#f7ae3a 100%);
                width: 9.125rem;
                height:9.125rem;
                border-radius: 50%;
                z-index: -1;
            }
        }
       ._card {
            position: relative;
            background: #FFFFFF;
            box-shadow: 0px 0px 15px rgba(127, 184, 0, 0.15);
            border-radius: 20px;
            padding: 3.125rem;
            margin-bottom: 3.125rem;
            min-height: 26rem;
            @media (max-width:600px) { 
                padding: 1.875rem;
                min-height: auto;
                margin-bottom: 1.25rem;
            }
            &:before {
                content:'';
                position: absolute;
                right: -0.9375rem;
                bottom: -2.3125rem;
                background: url('../assets/img/Check.png') center center / contain no-repeat;
                width: 5.625rem;
                height:5.625rem; 
                z-index: 1;
                @media (max-width:600px) { 
                  width: 3.5625rem;
                  height:3.5625rem; 
                  bottom: -1.3125rem;
                  right: -0.5rem;

                }              
            }
            &._extracheck:before {
                background: url('../assets/img/extracheck.png') center center / contain no-repeat;
                width: 7.25rem;
                height:7.25rem; 
                bottom: -3.75rem;
                right: -1.75rem;
                z-index: 1;
                @media (max-width:600px) { 
                  width: 4.5rem;
                  height:4.5rem;
                  bottom: -1.3125rem; 
                  right: -0.75rem;
                } 
            }
            h3 {
                font-style: normal;
                font-weight: normal;
                font-size: 1.75rem;
                line-height: 2.25rem;
                text-align: center;
                margin-bottom: 1.25rem;
                @media (max-width:600px) { 
                    font-size: 1.3125rem;
                    line-height: 1.5625rem; 
                } 
            }
            p {
                font-size: 1.0625rem;
                line-height: 1.625rem;
            }

       }
   }

</style>
